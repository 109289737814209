import React from 'react';
import {useEffect, useState} from "react";


function WasMachen() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;
    document.title = "Was machen Logopäd(inn)en?"
    return (
        <div>
            {isMobile ? (
                <div>
                <div className={"übertitelmobile"}><h3>Was machen Logopäd(inn)en</h3></div>
                <div className={"wasmmobile"}>
                    <p>Logopäd(inn)en diagnostizieren und therapieren Sprach-, Sprech-, Stimm- und Schluckstörungen.
                        Sie erstellen einen individuellen auf den Patienten zugeschnittenen Therapieplan.
                        Es werden meistens Einzeltherapien durchgeführt, die zwischen 30 und 60 Minuten dauern.
                        Ziel ist es, je nach Störungsbild, die Fähigkeiten des Patienten zu verbessern oder wiederherzustellen.
                        Die Angehörigen werden begleitend beraten, um auch Zuhause das in der Therapie Erarbeitete zu vertiefen.
                        Um behandeln zu können benötigen Logopäden eine ärztliche Verordnung für Logopädie.
                        Verordnungen stellen Kinderärzte, HNO-Ärzte, Phoniater, Kieferorthopäden, Neurologen und Hausärzte aus.
                        Für Kinder wird die Therapie in voller Höhe übernommen, für Erwachsene gemäß den gesetzlichen Bestimmungen.</p>
                </div>
                </div>
            ) : (
                <div>
            <div className={"übertitel"}><h3>Was machen Logopäd(inn)en</h3></div>
            <div className={"wasm"}>
            <p>Logopäd(inn)en diagnostizieren und therapieren Sprach-, Sprech-, Stimm- und Schluckstörungen.
                Sie erstellen einen individuellen auf den Patienten zugeschnittenen Therapieplan.
                Es werden meistens Einzeltherapien durchgeführt, die zwischen 30 und 60 Minuten dauern.
                Ziel ist es, je nach Störungsbild, die Fähigkeiten des Patienten zu verbessern oder wiederherzustellen.
                Die Angehörigen werden begleitend beraten, um auch Zuhause das in der Therapie Erarbeitete zu vertiefen.
                Um behandeln zu können benötigen Logopäden eine ärztliche Verordnung für Logopädie.
                Verordnungen stellen Kinderärzte, HNO-Ärzte, Phoniater, Kieferorthopäden, Neurologen und Hausärzte aus.
                Für Kinder wird die Therapie in voller Höhe übernommen, für Erwachsene gemäß den gesetzlichen Bestimmungen.</p>
            </div>
                </div>
                )}
        </div>
    )
}

export default WasMachen;