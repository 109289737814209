import React from 'react';
import {useEffect, useState} from "react";


function Kooperationspartner() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;
    document.title = "Kooperationspartner"
    return (
        <div>
        {isMobile ? (
            <div>
        <div className={"übertitelmobile"}><h3>Kooperationspartner</h3></div>
        <h4 className={"koopmobile"}>Wir haben Kooperationsverträge mit: </h4>
        <div className={"kooperationmobile"}>
            <ul style={{lineHeight:"25px"}}>
                <a style={{textDecoration:"none"}} href={"http://www.pflegezentrum-odenwald.de/Frühberatungsstelle"}><li>Frühberatungsstelle Odenwaldkreis</li></a>
                <a style={{textDecoration:"none"}} href={"https://www.diakonie-frankfurt-offenbach.de/ich-suche-hilfe/kinder-familie/fruehfoerderung-sichtweisen-2/"}><li>Pädagogische Frühförderstelle für blinde und sehbehinderte Kinder in Frankfurt</li></a>
                <a style={{textDecoration:"none"}} href={"https://www.sommerhoffpark.de/beratung/padagogisch-audiologische-beratungsstelle/"}><li>Pädagogisch-audiologische Beratungsstelle in Frankfurt</li></a>
                <a style={{textDecoration:"none"}} href={"https://www.vitos.de/gesellschaften/vitos-suedhessen/einrichtungen/vitos-kinder-und-jugendtagesklinik-fuer-psychische-gesundheit-hoechst-im-odenwald"}><li>Vitos Kinder- und Jugendtagesklinik für psychische Gesundheit in Höchst im Odenwald</li></a>
            </ul>
        </div>
        <div className={"koopmobile"} style={{paddingBottom:"30px"}}><p>Wir pflegen außerdem eine enge, auch beratende Zusammenarbeit mit anderen Einrichtungen (Schulen, Kindergärten, Pflegeheime, SP-Zentren, Reha-Zentren, Krankenhäusern u.v.m.)<br />

            <br /><b>Weitere Seiten mit Informationen, die Ihnen vielleicht weiterhelfen könnten:</b></p></div>
        <div style={{bottom:'30px'}}className={"kooperationmobile"}>
            <ul>
                <a href={"http://www.dbl-ev.de/"} style={{textDecoration:"none"}}><li>Bundesverband für Logopädie</li></a>
                <a href={"http://www.bvss.de/"} style={{textDecoration:"none"}}><li>Bundesvereinigung Stotterer-Selbsthilfe e.V.</li></a>
                <a href={"http://www.ssb-ev.de/"} style={{textDecoration:"none"}}><li>Selbsthilfeverband Schlaganfallbetroffene und gleichartig Behinderter e.V.</li></a>
                <a href={"http://www.dcig.de/"} style={{textDecoration:"none"}}><li>Deutsche Cochlear Implant Gesellschaft e.V.</li></a>
                <a href={"http://www.down-syndrom-netzwerk.de/"} style={{textDecoration:"none"}}><li>Down-Syndrom Netzwerk Deutschland e.V.</li></a>
                <a href={"https://kinderkliniken.de/leistungen/paediatrisches-dysphagie-zentrum-paedy/"} style={{textDecoration:"none"}}><li>Pädiatrisches Zentrum für Schlucken und Essen(PÄDY)</li></a>
                <a href={"http://www.dmsg.de/"} style={{textDecoration:"none"}}><li>DMSG – Multiple Sklerose</li></a>
                <a href={"http://www.dgm.org/"} style={{textDecoration:"none"}}><li>DGM- Deutsche Gesellschaft für Muskelerkrankte</li></a>
                <a href={"http://www.parkinson-selbsthilfe.de/"} style={{textDecoration:"none"}}><li>Parkinson Selbsthilfe</li></a>
                <a href={"http://www.hessenaphasie.de/"} style={{textDecoration:"none"}}><li>Hessischer Landesverband für die Rehabilitation der Aphasiker</li></a>
            </ul>
        </div>
    </div>
) : (
        <div>
            <div className={"übertitel"}><h3>Kooperationspartner</h3></div>
            <h4 className={"koop"}>Wir haben Kooperationsverträge mit: </h4>
                <div className={"kooperation"}>
                <ul style={{lineHeight:"25px"}}>
                    <a style={{textDecoration:"none"}} href={"http://www.pflegezentrum-odenwald.de/Frühberatungsstelle"}><li>Frühberatungsstelle Odenwaldkreis</li></a>
                    <a style={{textDecoration:"none"}} href={"https://www.diakonie-frankfurt-offenbach.de/ich-suche-hilfe/kinder-familie/fruehfoerderung-sichtweisen-2/"}><li>Pädagogische Frühförderstelle für blinde und sehbehinderte Kinder in Frankfurt</li></a>
                    <a style={{textDecoration:"none"}} href={"https://www.sommerhoffpark.de/beratung/padagogisch-audiologische-beratungsstelle/"}><li>Pädagogisch-audiologische Beratungsstelle in Frankfurt</li></a>
                    <a style={{textDecoration:"none"}} href={"https://www.vitos.de/gesellschaften/vitos-suedhessen/einrichtungen/vitos-kinder-und-jugendtagesklinik-fuer-psychische-gesundheit-hoechst-im-odenwald"}><li>Vitos Kinder- und Jugendtagesklinik für psychische Gesundheit in Höchst im Odenwald</li></a>
                </ul>
            </div>
            <div className={"koop"} style={{paddingBottom:"30px"}}><p>Wir pflegen außerdem eine enge, auch beratende Zusammenarbeit mit anderen Einrichtungen (Schulen, Kindergärten, Pflegeheime, SP-Zentren, Reha-Zentren, Krankenhäusern u.v.m.)<br />

                <br /><b>Weitere Seiten mit Informationen, die Ihnen vielleicht weiterhelfen könnten:</b></p></div>
            <div style={{bottom:'30px'}}className={"kooperation"}>
                <ul>
                    <a href={"http://www.dbl-ev.de/"} style={{textDecoration:"none"}}><li>Bundesverband für Logopädie</li></a>
                    <a href={"http://www.bvss.de/"} style={{textDecoration:"none"}}><li>Bundesvereinigung Stotterer-Selbsthilfe e.V.</li></a>
                    <a href={"http://www.ssb-ev.de/"} style={{textDecoration:"none"}}><li>Selbsthilfeverband Schlaganfallbetroffene und gleichartig Behinderter e.V.</li></a>
                    <a href={"http://www.dcig.de/"} style={{textDecoration:"none"}}><li>Deutsche Cochlear Implant Gesellschaft e.V.</li></a>
                    <a href={"http://www.down-syndrom-netzwerk.de/"} style={{textDecoration:"none"}}><li>Down-Syndrom Netzwerk Deutschland e.V.</li></a>
                    <a href={"https://kinderkliniken.de/leistungen/paediatrisches-dysphagie-zentrum-paedy/"} style={{textDecoration:"none"}}><li>Pädiatrisches Zentrum für Schlucken und Essen(PÄDY)</li></a>
                    <a href={"http://www.dmsg.de/"} style={{textDecoration:"none"}}><li>DMSG – Multiple Sklerose</li></a>
                    <a href={"http://www.dgm.org/"} style={{textDecoration:"none"}}><li>DGM- Deutsche Gesellschaft für Muskelerkrankte</li></a>
                    <a href={"http://www.parkinson-selbsthilfe.de/"} style={{textDecoration:"none"}}><li>Parkinson Selbsthilfe</li></a>
                    <a href={"http://www.hessenaphasie.de/"} style={{textDecoration:"none"}}><li>Hessischer Landesverband für die Rehabilitation der Aphasiker</li></a>
                </ul>
            </div>
        </div>
    )}
        </div>
    )
}

export default Kooperationspartner;