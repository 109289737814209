import React from 'react';
import {useEffect, useState} from "react";


function Störungsbilder() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;
    document.title = "Störungsbilder"
    return (
        <div>
            {isMobile ? (
                <div>
                    <div className={'stitelmobile'}><h3>Störungsbilder</h3><p>In unserer Praxis behandeln wir grundsätzlich sämtliche Störungsbilder. Manche Therapeuten haben ihre Schwerpunkte und Spezialgebiete. Es kann auch auf Englisch, Slowakisch und Ungarisch behandelt werden. Wir bieten auch Unterstützte Kommunikation (UK) an.</p>
                        <p>Das Arbeitsfeld der Logopädie umfasst vier zentrale Störungsbereiche: Sprach-, Sprech-, Stimm- und Schluckstörungen, wobei die Störungen auch in Kombination(Komplexe Störungen) auftreten können.</p></div>
                    <div className={"stoerungmobile"}>
                        <div className={"grid-containermobile"}>
                            <div className={"grid-itemmobile"}>
                                <p><br/>Sprachstörungen bei Kindern:</p>
                                <ul>
                                    <li>Sprachentwicklungsstörungen (SES)</li>
                                    <li>Störung des Lauterbwerbs</li>
                                    <li>Störung des Wortschatzes</li>
                                    <li>Störung der Grammatik</li>
                                    <li>Störung des Sprachverständisses</li>
                                    <li>Störung der auditiven Wahrnehmung</li>
                                    <li>Störung der Sprachverarbeitung</li>
                                    <li>Kindliche Aphasie</li>
                                    <li>Störungen der Schriftsprache, Lese-Rechtschreibschwäche</li>
                                </ul>
                            </div>
                            <div className={"grid-itemmobile"}>
                                <p><br/>Sprechstörungen bei Kindern:</p>
                                <ul>
                                    <li>Artikulationsstörung (Aussprachestörung)</li>
                                    <li>Stottern</li>
                                    <li>Poltern</li>
                                    <li>Audiogen bedingte Sprachstörung (Bei einer Hörstörung)</li>
                                    <li>Kindliche Sprachapraxie</li>
                                    <li>Verbale Entwicklungsdyspraxie</li>
                                </ul>
                            </div>
                            <div className={"grid-itemmobile"}>
                                <p><br/>Stimmstörung bei Kindern (Dysphonien)</p>
                                <ul>
                                    <li>Funktionelle Stimmstörungen</li>
                                    <li>Organisch bedingte Stimmstörung</li>
                                </ul>
                            </div>
                            <div className={"grid-itemmobile"}>
                                <p><br/>Schluckstörung bei Kindern (Dysphagie)</p>
                                <ul>
                                    <li>Funktionelle orofaziale Störungen (Myofunktionelle Störungen)</li>
                                    <li>Organisch bedingte Schluckstörung (ab Säuglingsalter) (Dysphagien)</li>
                                </ul>
                            </div>
                            <div className={"grid-itemmobile"}>
                                <p><br/>Komplexe Störungen (bei Kindern)</p>
                                <ul>
                                    <li>Autismus</li>
                                    <li>Dysathrie</li>
                                    <li>Hörstörungen</li>
                                    <li>Rhinophonie</li>
                                    <li>Mutismus</li>
                                    <li>Kinder mit Behinderungen z.B. Down-Syndrom, Cerebrale Bewegungsstörung, usw.
                                    </li>
                                    <li>Lippen-Kiefer-Gaumen-Segel-Fehlbildungen</li>
                                </ul>
                            </div>
                            <div className={"grid-itemmobile"}>
                                <p><br/>Sprachstörungen bei Erwachsenen</p>
                                <ul>
                                    <li>Aphasie (z.B. Verlust der Sprache nach einem Schlaganfall oder
                                        Schädelhirn-Verletzung)
                                    </li>
                                    <li>Störungen auf Lautebene</li>
                                    <li>Störung der Grammatik</li>
                                    <li>Störung des Sprachverständnisses</li>
                                    <li>Störung des Textverständnisses und der Lesefähigkeit</li>
                                    <li>Störung der Schriftsprache</li>
                                </ul>
                            </div>
                            <div className={"grid-itemmobile"}>
                                <p><br/>Sprechstörungen bei Erwachsenen</p>
                                <ul>
                                    <li>Störung der Artikulation (Lispeln)</li>
                                    <li>Stottern</li>
                                    <li>Poltern</li>
                                    <li>Audiogene Sprechstörung (nach Hörstörung)</li>
                                    <li>Sprechapraxie</li>
                                    <li>Stimmstörungen (Dysphonien)</li>
                                    <li>Funktionelle Stimmstörungen</li>
                                    <li>Organisch bedingte Stimmstörung</li>
                                    <li>Zustand nach Laringektomie (Kehlkopfentfernung)</li>
                                    <li>Schluckstörungen bei Erwachsenen (Dysphagien)</li>
                                    <li>Funktionelle Schluckstörungen (Myofunktionelle Störungen)</li>
                                    <li>Organisch bedingte Schluckstörungen (Dysphagien)</li>
                                </ul>
                            </div>
                            <div className={"grid-itemmobile"}>
                                <p><br/>Komplexe Störungen (bei Erwachsenen)</p>
                                <ul>
                                    <li>Dysarthrie</li>
                                    <li>Hörstörung (z.B. nach Cochlear Implantat)</li>
                                    <li>Rhinophonie/Rhinolalie</li>
                                    <li>Neuro-degenerative Erkrankungen z.B. "Morbus Parkinson", "Multiple Sklerose(MS)"
                                        oder Amyotrophe Lateralsklerose (ALS)
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={"stoerungendemobile"}>Es gibt
                            auch Stimm-, Sprech-, Sprach und Schluckstörungen die sehr selten sind und oben vielleicht nicht
                            erwähnt wurden. Wenn Sie nicht sicher sind ob ihre Störung zu unseren Behandlungsgebieten gehört
                            rufen Sie einfach an und fragen Sie nach. Wir helfen gerne und wenn nötig verweisen wir Sie
                            weiter.
                        </div>
                        <div>
                            <h1>
                            <br/>
                            </h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className={'stoerungtitel'}><h3>Störungsbilder</h3><p>In unserer Praxis behandeln wir grundsätzlich sämtliche Störungsbilder. Manche Therapeuten haben ihre Schwerpunkte und Spezialgebiete. Es kann auch auf Englisch, Slowakisch und Ungarisch behandelt werden. Wir bieten auch Unterstützte Kommunikation (UK) an.</p>
                        <p>Das Arbeitsfeld der Logopädie umfasst vier zentrale Störungsbereiche: Sprach-, Sprech-, Stimm- und Schluckstörungen, wobei die Störungen auch in Kombination(Komplexe Störungen) auftreten können.</p></div>
                    <div className={"stoerung"}>
                        <div className={"grid-container"}>
                            <div className={"grid-item"}>
                                <p><br/>Sprachstörungen bei Kindern:</p>
                                <ul>
                                    <li>Sprachentwicklungsstörungen (SES)</li>
                                    <li>Störung des Lauterbwerbs</li>
                                    <li>Störung des Wortschatzes</li>
                                    <li>Störung der Grammatik</li>
                                    <li>Störung des Sprachverständisses</li>
                                    <li>Störung der auditiven Wahrnehmung</li>
                                    <li>Störung der Sprachverarbeitung</li>
                                    <li>Kindliche Aphasie</li>
                                    <li>Störungen der Schriftsprache, Lese-Rechtschreibschwäche</li>
                                </ul>
                            </div>
                            <div className={"grid-item"}>
                                <p><br/>Sprechstörungen bei Kindern:</p>
                                <ul>
                                    <li>Artikulationsstörung (Aussprachestörung)</li>
                                    <li>Stottern</li>
                                    <li>Poltern</li>
                                    <li>Audiogen bedingte Sprachstörung (Bei einer Hörstörung)</li>
                                    <li>Kindliche Sprachapraxie</li>
                                    <li>Verbale Entwicklungsdyspraxie</li>
                                </ul>
                            </div>
                            <div className={"grid-item"}>
                                <p><br/>Stimmstörung bei Kindern (Dysphonien)</p>
                                <ul>
                                    <li>Funktionelle Stimmstörungen</li>
                                    <li>Organisch bedingte Stimmstörung</li>
                                </ul>
                            </div>
                            <div className={"grid-item"}>
                                <p><br/>Schluckstörung bei Kindern (Dysphagie)</p>
                                <ul>
                                    <li>Funktionelle orofaziale Störungen (Myofunktionelle Störungen)</li>
                                    <li>Organisch bedingte Schluckstörung (ab Säuglingsalter) (Dysphagien)</li>
                                </ul>
                            </div>
                            <div className={"grid-item"}>
                                <p><br/>Komplexe Störungen (bei Kindern)</p>
                                <ul>
                                    <li>Autismus</li>
                                    <li>Dysathrie</li>
                                    <li>Hörstörungen</li>
                                    <li>Rhinophonie</li>
                                    <li>Mutismus</li>
                                    <li>Kinder mit Behinderungen z.B. Down-Syndrom, Cerebrale Bewegungsstörung, usw.
                                    </li>
                                    <li>Lippen-Kiefer-Gaumen-Segel-Fehlbildungen</li>
                                </ul>
                            </div>
                            <div className={"grid-item"}>
                                <p><br/>Sprachstörungen bei Erwachsenen</p>
                                <ul>
                                    <li>Aphasie (z.B. Verlust der Sprache nach einem Schlaganfall oder
                                        Schädelhirn-Verletzung)
                                    </li>
                                    <li>Störungen auf Lautebene</li>
                                    <li>Störung der Grammatik</li>
                                    <li>Störung des Sprachverständnisses</li>
                                    <li>Störung des Textverständnisses und der Lesefähigkeit</li>
                                    <li>Störung der Schriftsprache</li>
                                </ul>
                            </div>
                            <div className={"grid-item"}>
                                <p><br/>Sprechstörungen bei Erwachsenen</p>
                                <ul>
                                    <li>Störung der Artikulation (Lispeln)</li>
                                    <li>Stottern</li>
                                    <li>Poltern</li>
                                    <li>Audiogene Sprechstörung (nach Hörstörung)</li>
                                    <li>Sprechapraxie</li>
                                    <li>Stimmstörungen (Dysphonien)</li>
                                    <li>Funktionelle Stimmstörungen</li>
                                    <li>Organisch bedingte Stimmstörung</li>
                                    <li>Zustand nach Laringektomie (Kehlkopfentfernung)</li>
                                    <li>Schluckstörungen bei Erwachsenen (Dysphagien)</li>
                                    <li>Funktionelle Schluckstörungen (Myofunktionelle Störungen)</li>
                                    <li>Organisch bedingte Schluckstörungen (Dysphagien)</li>
                                </ul>
                            </div>
                            <div className={"grid-item"}>
                                <p><br/>Komplexe Störungen (bei Erwachsenen)</p>
                                <ul>
                                    <li>Dysarthrie</li>
                                    <li>Hörstörung (z.B. nach Cochlear Implantat)</li>
                                    <li>Rhinophonie/Rhinolalie</li>
                                    <li>Neuro-degenerative Erkrankungen z.B. "Morbus Parkinson", "Multiple Sklerose(MS)"
                                        oder Amyotrophe Lateralsklerose (ALS)
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={"stoerungende"}>Es gibt
                            auch Stimm-, Sprech-, Sprach und Schluckstörungen die sehr selten sind und oben vielleicht nicht
                            erwähnt wurden. Wenn Sie nicht sicher sind ob ihre Störung zu unseren Behandlungsgebieten gehört
                            rufen Sie einfach an und fragen Sie nach. Wir helfen gerne und wenn nötig verweisen wir Sie
                            weiter.
                        </div>
                        <div>
                            <h1>
                                <br/>
                            </h1>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Störungsbilder;