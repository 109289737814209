import React from 'react';
import {useEffect, useState} from "react";


function Impressum() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;
    document.title = "Impressum"
    return (
        <div>
            {isMobile ? (
                <div>
                    <div><h1 className={"übertitelmobile"}>Impressum</h1></div>
                    <div className={"starttextmobile"}>
                        <h4>Logopädische Praxis Sandra Duffy-Heusel</h4>
                        <p>Geisrain 1 <br/>
                            64747 Breuberg<br/>
                            Tel. 06165/3637<br/>
                            E-Mail: info@logopaedie-breuberg.de<br/><br/>

                            Staatl. gepr. Logopäde im Sinne des Gesetzes über den Beruf des Logopäden vom 7. Mai 1980
                            (BGBl I S. 529).<br/> Amtl. Ausgabestelle für die Urkunde zur Führung der Berufsbez.
                            "Logopäde": Regierung von Unterfranken, 09.11.1983. <br/>

                            <h4>Zuständige Aufsichtsbehörde:</h4>
                            Kreisgesundheitsamt<br/>
                            64711 Erbach/Odenwald<br/>

                            <h4>Inhaltlich Verantwortlicher:</h4>
                            Sandra Duffy-Heusel<br/><br/>

                            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte
                            externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
                            verantwortlich. Alle Inhalte dieser Websites, einschließlich der Gestaltung, unterliegen dem
                            Urheberrecht. Alle Rechte vorbehalten, alle Angaben ohne Gewähr, Änderungen vorbehalten.
                        </p>
                </div>
                </div>
            ) : (
                <div>
                    <h1 className={"übertitel"}>Impressum</h1>
                    <div className={"starttext"}>
                        <h4>Logopädische Praxis Sandra Duffy-Heusel</h4>
                        <p>Geisrain 1 <br/>
                            64747 Breuberg<br/>
                            Tel. 06165/3637<br/>
                            E-Mail: info@logopaedie-breuberg.de<br/><br/>

                            Staatl. gepr. Logopäde im Sinne des Gesetzes über den Beruf des Logopäden vom 7. Mai 1980
                            (BGBl I S. 529).<br/> Amtl. Ausgabestelle für die Urkunde zur Führung der Berufsbez.
                            "Logopäde": Regierung von Unterfranken, 09.11.1983. <br/>

                            <h4>Zuständige Aufsichtsbehörde:</h4>
                            Kreisgesundheitsamt<br/>
                            64711 Erbach/Odenwald<br/>

                            <h4>Inhaltlich Verantwortlicher:</h4>
                            Sandra Duffy-Heusel<br/><br/>

                            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte
                            externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
                            verantwortlich. Alle Inhalte dieser Websites, einschließlich der Gestaltung, unterliegen dem
                            Urheberrecht. Alle Rechte vorbehalten, alle Angaben ohne Gewähr, Änderungen vorbehalten.
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Impressum;