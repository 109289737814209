import React from 'react';
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div className={"footer"}>
            <div className={"footer2"}>
                <p><Link to="/Impressum">Impressum</Link><p></p><Link to="/Dsgvo">Datenschutzerklärung</Link></p>
            </div>
        </div>
    )
}
export default Footer