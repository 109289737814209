// src/Title.js
import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";


function Navigation() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;
    return (
        <div>
            {isMobile ? (
                <mobilenav classname={"mobileheader"}>
                <ul className={"mobile"}>
                    <li><Link to="">Start</Link></li>
                    <li><Link to="/Wasmachen">Was machen Logopäd(inn)en?</Link></li>
                    <li><Link to="/Ueberuns">Über uns</Link></li>
                    <li><Link to="/Stoerungsbilder">Störungsbilder</Link></li>
                    <li><Link to="/Kooperationspartner">Kooperationspartner</Link></li>
                    <li><Link to="/Kontakt">Kontakt</Link></li>
                    <li><Link to="/Aktuell">Aktuell</Link></li>
                </ul>
                </mobilenav>
            ) : (
                <nav className={"header"}>
                    <ul>
                        <li><Link to="">Start</Link></li>
                        <li><Link to="/Wasmachen">Was machen Logopäd(inn)en?</Link></li>
                        <li><Link to="/Ueberuns">Über uns</Link></li>
                        <li><Link to="/Stoerungsbilder">Störungsbilder</Link></li>
                        <li><Link to="/Kooperationspartner">Kooperationspartner</Link></li>
                        <li><Link to="/Kontakt">Kontakt</Link></li>
                        <li><Link to="/Aktuell">Aktuell</Link></li>
                    </ul>
                </nav>
            )}
        </div>
    )
}

export default Navigation