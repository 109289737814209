import Praxis01_B from "./images/album/Praxis01_B.jpg";
import Praxis02_B from "./images/album/Praxis02_B.jpg";
import Praxis03_B from "./images/album/Praxis03_B.jpg";
import Praxis04_B from "./images/album/Praxis04_B.jpg";

export const album = [
    {
        description:'Wartezimmer',
        original: Praxis01_B
    },
    {
        description: 'Eingangsbereich',
        original: Praxis02_B
    },
    {
        description: 'Behandlungszimmer',
        original: Praxis03_B
    },
    {
        description: 'Behandlungszimmer',
        original: Praxis04_B
    }
];