import React from 'react';
import {useEffect, useState} from "react";


function Start() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;
    document.title = "Start"
    return (
        <div className={"start"}>
            {isMobile ? (
                <div>
                    <img src={require('./images/Team3 (6).jpg')} className={"startbildmobile"} width="100%" />
                <div className={"starttextmobile"}>
                    <p style={{fontSize: "larger"}}>Herzlich Willkommen auf der Homepage der</p>

                    <p><b>Logopädischen Praxis<br/>
                        Sandra Duffy-Heusel</b></p>

                    <p>Hier erfahren Sie Wissenswertes über unsere Praxis, unsere Therapieverfahren und Logopädie im Allgemeinen.</p>

                    <p>Unsere Praxis wurde im Mai 1988 eröffnet. Inmitten des alten Ortskerns des Breuberger Stadtteils Neustadt befinden sich unsere Praxisräume im historischen "Rodensteiner Haus"- 1569 erbaut und ältestes Gebäude am Platz.</p>

                    <p>Mein Praxisteam umfasst die Logopädinnen Jennifer Weyrauch, Judit Schneider, Michaela Neff, Christiane Eidenmüller, Fabienne Pietrusky, Nadine Stützer und Sophie Bischof.</p>

                    <p>Unsere Praxiszeiten sind von 7:30 bis 18 Uhr, die Termine werden in der Regel telefonisch vereinbart. Hausbesuche bieten wir nach ärztlicher Verordnung an.</p>
                </div>
                </div>
            ) : (
                <div>
                <img src={require('./images/Team3 (6).jpg')} className={"startbild"} width="100%" />
            <div className={"starttext"}>
                <p style={{fontSize: "larger"}}>Herzlich Willkommen auf der Homepage der</p>

                <p><b>Logopädischen Praxis<br/>
                    Sandra Duffy-Heusel</b></p>

                <p>Hier erfahren Sie Wissenswertes über unsere Praxis, unsere Therapieverfahren und Logopädie im Allgemeinen.</p>

                <p>Unsere Praxis wurde im Mai 1988 eröffnet. Inmitten des alten Ortskerns des Breuberger Stadtteils Neustadt befinden sich unsere Praxisräume im historischen "Rodensteiner Haus"- 1569 erbaut und ältestes Gebäude am Platz.</p>

                <p>Mein Praxisteam umfasst die Logopädinnen Jennifer Weyrauch, Judit Schneider, Michaela Neff, Christiane Eidenmüller, Fabienne Pietrusky und Nadine Stützer.</p>

                <p>Unsere Praxiszeiten sind von 7:30 bis 18 Uhr, die Termine werden in der Regel telefonisch vereinbart. Hausbesuche bieten wir nach ärztlicher Verordnung an.</p>
            </div>
                </div>
            )}
        </div>
    )
}

export default Start;