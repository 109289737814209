import React from 'react';
import {useEffect, useState} from "react";


function Aktuell() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;
    document.title = "Aktuell"
    return (
        <div>
            {isMobile ? (
                <div style={{paddingBottom: "50%"}}>
                    <div>
                        <div className={"übertitelmobile"}><h3>Stellenangebote</h3></div>
                        <img src={require('../images/Stellenanzeige.png')} width="80%" style={{paddingLeft: "10%"}}/>
                    </div>
                </div>
            ) : (
                <div style={{paddingBottom: "2px"}}>
                    <div>
                        <div className={"übertitel"}><h3>Stellenangebote</h3></div>
                        <img src={require('../images/Stellenanzeige.png')} width="40%" style={{paddingLeft: "420px"}}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Aktuell;
/*<h3 className={"übertitelmobile"}> Wir freuen uns, dass unsere Stellen zur Zeit alle besetzt sind!</h3>*/