import './App.css';
import './Header.jsx';
import './index.css';
import Navigation from "./Header";
import Footer from "./Footer";
import React, { useEffect } from 'react';
import Start from "./Start";
import WasMachen from "./pages/Wasmachen";
import Überuns from "./pages/Ueberuns";
import Störungsbilder from "./pages/Stoerungsbilder";
import Kooperationspartner from "./pages/Kooperationspartner";
import Kontakt from "./pages/Kontakt";
import Aktuell from "./pages/Aktuell";
import {Route, Router, Routes} from "react-router-dom";
import Datenschutzerklaerung from "./pages/Datenschutzerklaerung";
import Impressum from "./pages/Impressum";
import {useState} from "react";



function App() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;
    return (
        <div className="Praxis">
            {isMobile ? (
                <div>
            <img className={"logoheadermobile"} src={require('../src/images/hauslogo.png')} width="5%" />
            <div className={"titelmobile"}>
                <p>Logopädische Praxis <br/>
                    Sandra Duffy Heusel</p>
            </div>
            <div style={{position:"sticky",top:"0",zIndex:"10"}}>
            <Navigation/>
            </div>
                </div>
                ) : (
                    <div>
                <img className={"logoheader"} src={require('../src/images/hauslogo.png')} width="8%" />
                <div className={"titel"}>
                <p>Logopädische Praxis <br/>
                Sandra Duffy Heusel</p>
                </div>
                <div style={{position:"sticky",top:"0",zIndex:"10"}}>
                <Navigation/>
                </div>
                    </div>
                )}
            <Routes>
                <Route path="" element={<Start />}/>
                <Route path="/Wasmachen" element={<WasMachen />}/>
                <Route path="/Ueberuns" element={<Überuns />}/>
                <Route path="/Stoerungsbilder" element={<Störungsbilder />}/>
                <Route path="/Kooperationspartner" element={<Kooperationspartner />}/>
                <Route path="/Kontakt" element={<Kontakt />}/>
                <Route path="/Aktuell" element={<Aktuell/>}/>
                <Route path="/Dsgvo" element={<Datenschutzerklaerung/>}/>
                <Route path="/Impressum" element={<Impressum/>}/>
            </Routes>
            <Footer />
        </div>
    );
}

export default App;