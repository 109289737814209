import React, { useMemo } from 'react';
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import {useEffect, useState} from "react";

function Kontakt() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;
    document.title = "Kontakt"
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyBVOia7rUmD0s5rjEWHQjs53Tqs5SNyubY",
    });
    const center = useMemo(() => ({ lat: 49.8171971103634, lng: 9.035827213852667, }), []);

    return (
        <div>
            {isMobile ? (
                <div>
                    <div className={"übertitelmobile"}><h3>Kontakt</h3></div>
                    <h4 style={{textAlign:"center",paddingTop:"10px",fontSize:"12px"}}>Adresse:</h4>
                    <p style={{textAlign:"center",lineHeight:"15px",fontSize:"10px"}}>Logopädische Praxis<br/>
                        Sandra Duffy-Heusel<br/>
                        Geisrain 1<br/>
                        64747 Breuberg<br/>
                        Tel. 06165/3637<br/>
                        E-Mail: info@logopaedie-breuberg.de</p>
                    <div style={{height: "400px",
                        width: "70%",paddingLeft:"8%",paddingTop:"30px"}}>
                        {!isLoaded ? (
                            <h1>Loading...</h1>
                        ) : (
                            <GoogleMap
                                mapContainerStyle={{ height: "60%",
                                    width: "120%"}}
                                center={center}
                                zoom={18}
                            >
                                <MarkerF position={{ lat: 49.81718444824219, lng: 9.035828590393066 }} />
                            </GoogleMap>
                        )}
                    </div>
                </div>
            ) : (
                <div>
            <div className={"übertitel"}><h3>Kontakt</h3></div>
            <h4 style={{textAlign:"center",paddingTop:"30px",fontSize:"18px"}}>Adresse:</h4>
            <p style={{textAlign:"center",lineHeight:"25px",fontSize:"17px"}}>Logopädische Praxis<br/>
                Sandra Duffy-Heusel<br/>
                Geisrain 1<br/>
                64747 Breuberg<br/>
                Tel. 06165/3637<br/>
                E-Mail: info@logopaedie-breuberg.de</p>
            <div style={{height: "600px",
                width: "70%",paddingLeft:"21%"}}>
                {!isLoaded ? (
                    <h1>Loading...</h1>
                ) : (
                    <GoogleMap
                        mapContainerStyle={{ height: "550px",
                            width: "850px"}}
                        center={center}
                        zoom={18}
                    >
                        <MarkerF position={{ lat: 49.81718444824219, lng: 9.035828590393066 }} />
                    </GoogleMap>
                )}
            </div>
        </div>
            )}
        </div>
    )
}

export default Kontakt;